<template lang="pug">
  v-container
    v-toolbar(
      color="primary"
      dark
    )
      span Purchase Request
      v-spacer
      v-icon mdi-account
    v-data-table(
      :items="purchaseRequestIndexList"
      :headers="headers"
      :loading="purchaseRequestIndexGetting"
      :server-items-length="purchaseRequestIndexGetCount"
      single-expand
      :page.sync="page"
      :items-per-page="itemsPerPage"
    )
      template(v-slot:top)
        v-row
          v-col(cols="3")
            v-text-field(
              label="Search"
              append-icon="mdi-magnify"
              v-model="search"
            )
      template(v-slot:item="{ item, expand, isExpanded }")
        tr
          td {{ item.purchase_request_no }}
          td {{ prAmount(item).phpFormat() }}
          td {{ item.status.description }}
          td
            v-btn(
              color="yellow"
              text
              @click="$router.push({ name: 'purchase-request.edit', params: { purchaseRequest: item.id } })"
              small
            )
              v-icon mdi-pencil
            v-btn(
              color="green"
              text
              @click="$router.push({ name: 'purchase-request.detail.edit', params: { purchaseRequest: item.id } })"
              small
            )
              v-icon mdi-poll
            v-btn(
              color="blue-grey"
              text
              small
              @click="expand(!isExpanded)"
            )
              v-icon(
                :class="isExpanded ? 'is-expanded' : ''"
              ) mdi-chevron-down
      template(v-slot:expanded-item="{ item }")
        tr
          td(colspan="4")
            v-simple-table
              template(v-slot:default)
                thead
                  tr
                    th Stock
                    th Qty
                    th Unit
                    th Cost
                tbody
                  tr(v-for="(detail, index) in item.details" :key="index")
                    td {{ detail.stock_id }}
                    td {{ detail.quantity }}
                    td {{ detail.unit }}
                    td.text-right {{ detail.cost.phpFormat() }}
                  tr
                    th(colspan="3") Total
                    td.text-right {{ prAmount(item).phpFormat() }}
</template>
<style lang="sass" scoped>
  .is-expanded
    transform: rotate(-180deg)
</style>
<script>
import purchaseRequestRepository from '@/repositories/purchase-request.repository'
import { requestVars } from '@/libs/api-helper.extra'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'

const [purchaseRequestIndexVars, purchaseRequestIndexVarNames] = requestVars({ identifier: 'purchase-request-index', pagination: true })

const purchaseRequestIndexHandler = new VueRequestHandler(null, purchaseRequestIndexVarNames, {}, true)

const tableVars = () => ({
  headers: [
    { text: 'ID', value: 'pr_id' },
    { text: 'Amount', value: 'amount' },
    { text: 'PR Status', value: 'status_id' },
    { text: 'Config', sortable: false },
  ],
  page: 1,
  itemsPerPage: 10,
  search: null,
})

export default {
  name: 'TablePurchaseRequest',
  components: {
    tooltipBtn: () => import('@/components/extra/TooltipBtn'),
  },
  created () {
    this.getPurchaseRequests()
  },
  data () {
    return {
      ...tableVars(),
      ...purchaseRequestIndexVars,
    }
  },
  watch: {
    page () {
      this.getPurchaseRequests()
    },
    itemsPerPage () {
      this.getPurchaseRequests()
    },
    search () {
      this.getPurchaseRequests()
    },
  },
  methods: {
    getPurchaseRequests () {
      const handler = purchaseRequestIndexHandler
      const repository = purchaseRequestRepository.index
      const params = {
        page: this.page,
        limit: this.itemsPerPage,
      }
      handler.setVue(this)
      handler.execute(repository, [params])
    },
    prAmount (item) {
      const details = item.details || []
      return details.reduce((t, c) => t + c.cost, 0)
    },
  },
}
</script>
